.audio-player {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  padding: 10px;
  box-sizing: border-box;
  position: relative;
  background: linear-gradient(
    135deg,
    #1c1f2b,
    #2a2e45
  ); /* Dark gradient background */

  audio {
    display: none; /* Hide default audio controls */
  }

  .play-button {
    background-color: #f39c12; /* Vibrant orange color */
    color: #fff;
    font-size: 5rem; /* Default size */
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    box-shadow: 0px 0px 15px rgba(243, 156, 18, 0.6); /* Soft shadow */
    transition: background-color 0.3s ease, transform 0.2s ease,
      box-shadow 0.3s ease;

    /* Glowing border on hover */
    &:hover {
      background-color: #e67e22;
      box-shadow: 0px 0px 25px rgba(243, 156, 18, 1),
        0px 0px 45px rgba(243, 156, 18, 0.7);
      transform: scale(1.1); /* Slightly increase size on hover */
    }

    /* Responsive scaling */
    @media (max-width: 600px) {
      width: 75vw;
      height: 75vw;
      font-size: 40vw;
    }

    @media (min-width: 601px) and (max-width: 1024px) {
      width: 40vw;
      height: 40vw;
      font-size: 30vw;
    }

    @media (min-width: 1025px) {
      width: 25vw;
      height: 25vw;
      font-size: 17vw;
    }
  }
}
