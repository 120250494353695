html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #1c1f2b, #2a2e45);
  font-family: "Montserrat", sans-serif;
  color: #f0f0f0;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.05);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.5);
}

h1 {
  margin-bottom: 20px;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
}
