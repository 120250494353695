.video-player {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1f1f2e;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);

  video {
    border: 2px solid #f39c12;
    border-radius: 10px;
    max-width: 100%;
    height: auto;
  }

  video::-webkit-media-controls-play-button {
    background-color: #f39c12;
    border-radius: 50%;
    height: 40px;
    width: 40px;
  }

  video::-webkit-media-controls-play-button:hover {
    background-color: #e67e22;
  }
}
